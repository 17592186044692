<template>
  <div>
    <PettyCashStats @clicked="filterlist" />
    <v-card elevation="0" class="mx-auto mt-10" flat>
      <v-toolbar flat dense>
        <div class="headline text-lg-h6 text-uppercase">
          My {{ caption ? caption : "Petty Cash" }} Requests
        </div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          hide-details
          label="Search"
          class="mx-2"
          dense
          single-line
        />
        <v-menu
          v-model="filterDatePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="auto"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              color="primary"
              dense
              single-line
              hide-details
              label="Filter by Posting Date"
              prepend-inner-icon="filter_alt"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterDate"
            range
            @input="
              (filterDatePicker = false),
                (startDate = filterDate[0]),
                (endDate = filterDate[1])
            "
          ></v-date-picker>
        </v-menu>
        <v-spacer />
        <v-btn class="pettycash__buttons" color="primary" link to="form">
          <v-icon class="mx-2"> mdi-plus </v-icon>
          New Request
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <v-row class="no-gutters mt-3">
          <v-col cols="12" md="12">
            <v-sheet class="">
              <div class="px-3 py-3">
                <v-data-table
                  :headers="headers"
                  :items="pettycash"
                  :sort-desc.sync="sortDesc"
                  ref="pettycashtable"
                  multi-sort
                  class="elevation-0"
                  :items-per-page="8"
                  id="scollStyle"
                  :search="search"
                  :sort-by.sync="sortBy"
                >
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.no="{ item }">
                    {{ item.no }}
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.status="{ item }">
                    <v-chip small label :color="getColor(item.status).color">
                      <div style="color: #fff">{{ item.status }}</div>
                    </v-chip>
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.total_amount="{ item }">
                    {{
                      item.currencyCode.length > 0
                        ? `(${item.currencyCode})`
                        : defaultCurrencyCaption
                    }}
                    {{ item.totalAmount | currencyFormat }}
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.payee_name="{ item }">
                    {{ item.payeeName }}
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.posting_date="{ item }">
                    {{ getDateFormat(item.postingDate) }}
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.reference_no="{ item }">
                    {{ item.referenceNo }}
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.actions="{ item }">
                    <div class="d-flex flex-row">
                      <v-btn
                        dense
                        small
                        class="mx-1"
                        color="primary"
                        :to="`form?edit=${encodeRoute(item.no)}`"
                      >
                        <div class="px-2">View</div>
                        <v-icon small>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn
                        dense
                        small
                        class="mx-1"
                        v-if="item.status === 'Released'"
                        color="teal lighten-1"
                        :to="{
                          name: 'PettycashDocument',
                          params: { docNo: item.no },
                        }"
                      >
                        <div class="px-2">document</div>
                        <v-icon small>description</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PettyCashStats from "./PettyCashStats.vue";
import PettyCashMethods from "../pettyCashMethods.js";
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
export default {
  name: "PettyCashList",
  mixins: [RouterMixin],
  components: { PettyCashStats },
  data: function () {
    return {
      status: "All Requests",
      details: {},
      filterDate: [],
      filterDatePicker: "",
      sortBy: "no",
      sortDesc: true,
      search: "",
      currencyCode: "KES",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("pettycash/getPettyCash");
    });
  },
  computed: {
    headers: function () {
      return [
        { text: "#No.", align: "start", sortable: true, value: "no" },
        { text: "Status", sortable: true, value: "status", align: "center" },
        { text: "Description", sortable: true, value: "description" },
        {
          text: "Posting Date",
          sortable: true,
          value: "posting_date",
          align: "center",
        },
        {
          text: "Total Amount",
          sortable: true,
          value: "total_amount",
          align: "end",
        },
        { text: "Actions", sortable: false, value: "actions" },
      ];
    },
    caption() {
      const user = AuthService.user;
      return user.captions.pettycash_caption;
    },
    dateRangeText() {
      return this.filterDate.join(" To ");
    },
    loader: function () {
      return this.$store.getters["pettycash/pettycashstate"]("loader");
    },
    lines: function () {
      return this.$store.getters["pettycash/pettycashstate"]("lines");
    },
    currencies() {
      return this.$store.getters["imprest/imprestGetters"]("currencies");
    },
    pettycash: function () {
      if (this.filterDate.length !== 0) {
        return this.status === "All Requests"
          ? this.$store.getters["pettycash/pettycashstate"]("pettycash").filter(
              (a) =>
                new Date(a.postingDate) >= this.startDate &&
                new Date(a.postingDate) <= this.endDate
            )
          : this.$store.getters["pettycash/pettycashstate"]("pettycash").filter(
              (a) =>
                new Date(a.postingDate) >= this.startDate &&
                new Date(a.postingDate) <= this.endDate &&
                a.documentType === this.documentType &&
                a.status === this.status
            );
      }
      return this.$store.getters["pettycash/pettycashstate"]("pettycash");
    },
    cancelModal: {
      get() {
        return this.$store.getters["pettycash/pettycashstate"]("cancelModal");
      },
      set(value) {
        this.$store.dispatch("pettycash/openCancelModal", value);
      },
    },
    settings() {
      let settings = this.$store.getters["Admin/adminGetters"]("settings");
      return Object.keys(settings).length > 0
        ? JSON.parse(settings.cashmgt_settings)
        : null;
    },
    defaultCurrencyCaption() {
      return this.settings.default_currency_caption
        ? `(${this.settings.default_currency_caption})`
        : `(${this.currencyCode})`;
    },
  },
  methods: {
    ...PettyCashMethods,
    filterlist(statusfilter) {
      this.status = statusfilter;
    },
  },
  watch: {
    filterDate() {
      if (this.filterDate.length === 1) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[0]);
      }
      if (this.filterDate.length === 2) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[1]);
      }
    },
  },
};
</script>
<style>
@import url("../styles.css");
</style>
