<template>
  <v-col cols="12" md="12">
    <v-card flat>
      <v-card-title> Approval Entries </v-card-title>
      <v-card-text>
        <v-timeline dense class="mx-10" v-if="approvalEntries !== 0">
          <v-timeline-item
            v-for="(item, i) in approvalEntries"
            :key="i"
            :color="getColor(item.status).icon_color"
            small
            fill-dot
            :icon="getColor(item.status).icon"
          >
            <v-row>
              <v-col cols="5">
                <div class="d-flex justify-start">
                  <v-icon>schedule</v-icon>
                  <strong>{{
                    getEntryDateFormat(item.dateTimeSentForApproval)
                  }}</strong>
                </div>
                <div class="d-flex justify-start">
                  <div class="text-lg-h6">Entry: {{ item.entryNo }}</div>
                  <v-chip
                    label
                    class="mx-1 my-1"
                    :color="getColor(item.status).icon_color"
                    small
                  >
                    {{ item.status }}
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="7">
                <v-list-item>
                  <v-list-item-avatar
                    :color="getColor(item.status).icon_color"
                    style="color: rgb(0, 68, 139)"
                    size="50"
                  >
                    {{ getApproverValue(item.approver, "number") }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ getApproverValue(item.approver, "name") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ getApproverValue(item.approver, "email") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-list-item v-if="hasApprovalComments(item)">
                <v-list-item-content>
                  <v-list-item-title> Comments </v-list-item-title>
                  <v-list-item-subtitle
                    v-for="(comment, key) in item.approvalComments"
                    :key="key"
                  >
                    - {{ comment.comment }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import LeaveMethods from "../LeaveMethods";
export default {
  name: "ApprovalEntries",
  props: { approvalEntries: Array },
  methods: {
    ...LeaveMethods,
    getApproverValue: function (approver, label) {
  if (label === "name") {
    const names = [
      approver?.first_name || approver?.firstName || "",  
      approver?.last_name || approver?.lastName || ""  
    ];
    return names.join(" ").trim(); 
  } else if (label === "email") {
    return approver?.email || "N/A"; 
  } else if (label === "number") {
    return approver?.number || "N/A"; 
  }

  return "";
},
hasApprovalComments(item) {
      return item && Array.isArray(item.approvalComments) && item.approvalComments.length > 0;
    }
  },
};
</script>
